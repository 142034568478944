import { createGlobalStyle } from "styled-components"
import reboot from "styled-reboot"
import theme from "./theme"
import { media } from "styled-bootstrap-grid"

export const GlobalStyles = createGlobalStyle`
  ${reboot(theme.options)}
  
  *{
    //outline: 1px solid red !important;
  }
  
  html{
    background-color: #e0e0e0;
  }

  body {
    -webkit-font-smoothing: antialiased;
    margin: 0 auto;
    max-width: 1440px;
    overflow-x: hidden;
    background-color: #E7E8E9;
  }
  
  h1, h2, h3, h4, h5, h6{
    font-family: ${theme.headingFontFamily};
    color: ${theme.secondaryColor};
    line-height: 150%;
    padding-bottom: 10px;
    display: inline-block;
    
    span{
      display: inline;
      border-bottom: 4px solid ${theme.primaryColor};
    }
  }
  
  h1{
    font-size: 3rem;
    ${media.sm`
      font-size: 4.375rem;
    `}
  }
  
  h2{
    font-size: 2.75rem;
    margin-bottom: 2rem;
    ${media.sm`
      font-size: 4rem;  
    `}
  }
  
   h3{
    font-size: 2.25rem;
  }
  
  h4{
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  body.has-banner {
    // This fixes the issue where the top banner can cover the anchor headings
    h1::before, h2::before, h3::before{
      display: block; 
      content: " "; 
      margin-top: -60px; 
      height: 60px; 
      visibility: hidden; 
      pointer-events: none;
    } 
  }
  
  iframe{
    display: block;
  }
  
  input:not([type="submit"]){
    font-size: 16px;
    padding: 10px 15px;
  }
  
  input, select, textarea{
    border: none;
  }
  
  p{
    font-size: 1.25rem;
    line-height: 150%;
    margin-bottom: 3rem;
  }
  
  p:last-child, ul:last-child{
    margin-bottom: 0;
  }
  
  img{
    max-width: 100%;
  }
  
  header + .image-with-text{
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: contain;
      h1, p, a{
        color: #fff !important;
      }
      
      .body-content{
        justify-content: flex-start;
      }
  }
  
  #gatsby-focus-wrapper{
    overflow: hidden;
  }
`