import * as React from 'react'
import baseStyled, {ThemedStyledInterface} from "styled-components"

const primaryColor = "#9B3D28";
// const bodyFontFamily = 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
const bodyFontFamily = '"Source Sans Pro", Roboto, "Helvetica Neue", Arial, sans-serif';

export const theme = {
    primaryColor,
    secondaryColor: "#1B2830",
    buttonTextColor: "#fff",
    bodyFontFamily,
    roundness: "10px",
    // headingFontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    headingFontFamily: '"Source Sans Pro", "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif',
    breakpoints: { // defaults below
        giant: 1200,
        xl: 1200,
        lg: 992,
        md: 768,
        sm: 576,
        xs: 575,
        // or you can use aliases
        // giant: 1200,
        // desktop: 992,
        // tablet: 768,
        // phone: 576,
        // smaller: 575,
    },
    spacing: {
        '0': '0 !important',
        '1': '1rem !important',
        '2': '2rem !important',
        '3': '3rem !important',
        '4': '4rem !important',
        '5': '5rem !important',
        '6': '6rem !important',
        '7': '9rem !important',
    },
    row: {
        padding: 8, // default 15
    },
    col: {
        padding: 8, // default 15
    },
    container: {
        padding: 0, // default 15
        maxWidth: { // defaults below
            xl: 1140,
            lg: 960,
            md: "100%",
            sm: "100%",
            xs: "100%",
            // or you can use aliases
            // giant: 1140,
            // desktop: 960,
            // tablet: 720,
            // phone: 540,
            // smaller: 540,
        },
    },
    options: {
        black: "#000",
        fontFamilyBase: bodyFontFamily,
        fontFamilyMonospace:
            "SFMono-Regular, Menlo, Monaco, Consolas, \"Liberation Mono\", \"Courier New\", monospace",
        fontSizeBase: "1rem",
        fontWeightBase: 400,
        lineHeightBase: 2,
        bodyColor: "#414141",
        bodyBg: "#fff",
        headingsMarginBottom: "0.5rem",
        paragraphMarginBottom: "1rem",
        labelMarginBottom: "0.5rem",
        dtFontWeight: 700,
        linkColor: `${primaryColor}`,
        linkDecoration: "none",
        linkHoverColor: primaryColor,
        linkHoverDecoration: "underline",
        tableCellPadding: "0.75rem",
        textMuted: "#6c757d",
    }
}

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
export default theme


